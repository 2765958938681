<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <br />
    <v-skeleton-loader
      v-if="isloading"
     
      type=" divider, list-item-three-line"
    ></v-skeleton-loader>
    <div v-else>
      <v-card class="card">
        <v-card-title class="heading justify-center">
          <span>User Active & InActive</span>
        </v-card-title>
        <v-card-text>
          <div class="add-section">
            <div>
              <v-form ref="form">
                <v-col cols="6" sm="12" lg="6" class="text">
                  <v-col class="pb-0" style="text-align: start">
                    <label class="add-text">User Name</label>
                    <v-autocomplete
                      v-model="selecteduser"
                      @change="findUserInfo()"
                      :items="userlist"
                      item-text="displayname"
                      item-value="id"
                      outlined
                      dense
                      clearable
                      required
                    ></v-autocomplete>
                  </v-col>
                </v-col>
              </v-form>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <div v-if="userInfo" class="cus-card">
        <v-row>
          <div style="width: 80%">
            <v-row style="padding: 0; margin: 0">
              <v-col style="padding: 0; margin: 0" lg="2">
                <label class="c-label">Reg/Emp Code</label></v-col
              >
              <v-col style="padding: 0; margin: 0"
                ><span>: {{ empmemberinfo.uid_no }}</span></v-col
              >
              <v-col style="padding: 0; margin: 0" lg="2">
                <label class="c-label">Is Active </label></v-col
              >
              <v-col style="padding: 0; margin: 0"
                ><span v-if="empmemberinfo.isactive == true">: YES</span>
                <span v-else>: NO</span>
              </v-col>
            </v-row>
            <v-row style="padding: 0; margin: 0">
              <v-col style="padding: 0; margin: 0" lg="2">
                <label class="c-label">Name</label></v-col
              >
              <v-col style="padding: 0; margin: 0"
                ><span>: {{ empmemberinfo.name }}</span>
              </v-col>

              <v-col style="padding: 0; margin: 0" lg="2">
                <label class="c-label">Checked-Out Book</label></v-col
              >
              <v-col style="padding: 0; margin: 0"
                ><span
                  >: {{ empmemberinfo.total_checked_out_book }}/{{
                    empmemberinfo.max_number_book_allowed
                  }}</span
                >
              </v-col>
            </v-row>
            <v-row style="padding: 0; margin: 0">
              <v-col style="padding: 0; margin: 0" lg="2">
                <label style="padding: 0; margin: 0" class="c-label"
                  >Contact</label
                ></v-col
              >
              <v-col style="padding: 0; margin: 0"
                ><span style="padding: 0; margin: 0"
                  >: {{ empmemberinfo.mobile_number }}</span
                >
              </v-col>
              <v-col style="padding: 0; margin: 0" lg="2">
                <label class="c-label">Email</label></v-col
              >
              <v-col style="padding: 0; margin: 0"
                ><span style="padding: 0; margin: 0"
                  >: {{ empmemberinfo.email }}</span
                >
              </v-col>
            </v-row>
            <v-row style="padding: 0; margin: 0">
              <v-col style="padding: 0; margin: 0" lg="2">
                <label class="c-label">User Type</label></v-col
              >
              <v-col style="padding: 0; margin: 0"
                ><span>: {{ empmemberinfo.usertype }}</span></v-col
              >

              <v-col
                v-if="empmemberinfo.program != ''"
                style="padding: 0; margin: 0"
                lg="2"
              >
                <label class="c-label">Program</label></v-col
              >
              <v-col
                v-if="empmemberinfo.program != ''"
                style="padding: 0; margin: 0"
                ><span style="padding: 0; margin: 0"
                  >: {{ empmemberinfo.program }}</span
                >
              </v-col>
            </v-row>
            <v-row style="padding: 0; margin: 0">
              <v-col
                v-if="empmemberinfo.rollno != ''"
                style="padding: 0; margin: 0"
                lg="2"
              >
                <label class="c-label">Roll No</label></v-col
              >
              <v-col
                v-if="empmemberinfo.rollno != ''"
                style="padding: 0; margin: 0"
                ><span style="padding: 0; margin: 0"
                  >: {{ empmemberinfo.rollno }}</span
                >
              </v-col>
              <v-col
                v-if="empmemberinfo.division != ''"
                style="padding: 0; margin: 0"
                lg="2"
              >
                <label class="c-label">Division</label></v-col
              >
              <v-col
                v-if="empmemberinfo.division != ''"
                style="padding: 0; margin: 0"
                ><span style="padding: 0; margin: 0"
                  >: {{ empmemberinfo.division }}</span
                >
              </v-col>
            </v-row>
            <v-row style="padding: 0; margin: 0">
              <v-col
                v-if="empmemberinfo.year"
                style="padding: 0; margin: 0"
                lg="2"
              >
                <label class="c-label">Year</label></v-col
              >
              <v-col v-if="empmemberinfo.year" style="padding: 0; margin: 0"
                ><span style="padding: 0; margin: 0"
                  >: {{ empmemberinfo.year }}</span
                >
              </v-col>
              <v-col
                v-if="empmemberinfo.usertype != 'Employee'"
                style="padding: 0; margin: 0"
                lg="2"
              >
                <label class="c-label">Date Membership</label></v-col
              >
              <v-col
                v-if="empmemberinfo.usertype != 'Employee'"
                style="padding: 0; margin: 0"
                ><span
                  >:
                  {{
                    empmemberinfo.date_of_membership
                      .split("T")[0]
                      .split("-")[2] +
                    "-" +
                    empmemberinfo.date_of_membership
                      .split("T")[0]
                      .split("-")[1] +
                    "-" +
                    empmemberinfo.date_of_membership.split("T")[0].split("-")[0]
                  }}</span
                >
              </v-col>
            </v-row>
          </div>
          <div>
            <v-img height="150" width="150" :src="url">
              <template v-slot:placeholder>
                <v-icon size="150">mdi mdi-account</v-icon>
              </template>
            </v-img>
          </div>
        </v-row>
        
      </div>
      <div  v-if="userInfo">
      <v-row v-if='empmemberinfo.isactive' class="pa-5" align="center" justify="center">
    <v-col cols="12" sm='4' mg="4">
         <v-text-field filled rounded v-model="reason" @input="btndisable()"  label="Enter Reason For In Aative User"  single-line hide-details> </v-text-field></v-col>
         </v-row>
           <v-row class="pa-5" align="center" justify="center">
    <v-col cols="12" sm='4' mg="4">
      <center>   <v-btn :disabled='button_disable'  @click="ActiveAndInActive()" x-large filled rounded  class="primary"  single-line hide-details> <span v-if="empmemberinfo.isactive">In Active</span><span v-else>Active</span> </v-btn></center></v-col>
         </v-row>
      </div>
    </div>
    
  </div>
  
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard

export default {
  data: () => ({
    snackbar_msg: "",
    snackbar: false,
    color: "",
    userInfo: false,
    isloading: false,
    userlist: [],
    selecteduser: "",
    isphoto: false,
    image_url: "",
    url: "",
    btnname:"",
    empmemberinfo:"",
    button_disable:true,
    reason:"",
  }),
  mounted() {
    this.onLoad();
 this.selecteduser = parseInt(localStorage.getItem('user_id'));
 if(this.selecteduser!='')
 this.findUserInfo();
  },
  methods: {
      btndisable()
      {
            if(this.reason=="")
            this.button_disable=true;
            else
            this.button_disable=false
      },
      ActiveAndInActive()
      {
 
  var params={'userid':this.selecteduser,'reson':this.reason,'isactive':!this.empmemberinfo.isactive};
      axios.post("IssueBook/saveUserActiveOrNot",params).then((res) => {
      
        if (res.data.msg == "200") {
         this.findUserInfo();
       
        } else {
       
        }
      });
      },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    onLoad() {
      this.isloading = true;
      axios.get("admin/fetchAllUserwithname").then((res) => {
        //console.log("res------->"+res.data.userType)
        if (res.data.msg == "200") {
          this.userlist = res.data.userlist;
          this.isloading = false;
        } else {
          this.isloading = false;
        }
      });
    },

    findUserInfo() {
        this.reason="";
      var params = { userid: this.selecteduser };
      this.userInfo = false;
      axios
        .post("/IssueBook/getEmpMemInformationbyUser_ID", params)
        .then((res) => {
         
          if (res.data.msg == "200") {
            this.userInfo = true;
            this.empmemberinfo = res.data.memberinfo;
            this.getimage(this.empmemberinfo.uid_no);
            if(this.empmemberinfo.isactive)
            this.btnname='In Active';
            else
            this.btnname='Active';
               if(!this.empmemberinfo.isactive)
        this.button_disable=false;
        else
        this.button_disable=true;
          } else {
            this.showSnackbar("red", res.data.msg);
            this.userInfo = false;
          }
        });
    },
    getimage(value) {
      var params = { prn: value };
      axios.post("/ImportData/getphotfromerp", params).then((res) => {
        if (res.data.msg == "200") {
          this.url = res.data.url;
          this.isphoto = res.data.isphoto;
        }
      });
    },
  },
};
</script>
<style scoped>
.heading {
  background-color: #3f51b5;
  padding: 0.3rem;
  color: white;
}
.v-icon {
  cursor: pointer;
}
.text >>> .v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}
.check >>> .v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}
.p-btn {
  padding: 0px 12px 12px 12px;
}
.add-section {
  display: block;
  text-align: -webkit-center;
}
.checkbox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}
</style>
